import module from '../../../module';

import templateUrl from './item-actions.template.html';

class ItemActionsComponent {

  constructor($location, confirmation, branchService, command, $route, pawnItemCache, pawnService, authentication) {
    this.$location = $location;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.command = command;
    this.$route = $route;
    this.pawnItemCache = pawnItemCache;
    this.pawnService = pawnService;
    this.authentication = authentication;
  }

  async $onInit() {
    this.branches = await this.branchService.toPromise();
  }

  isReadyForSaleOrReservation() {
    return this.item.status === 'READY_FOR_SALE';
  }

  isReservationActive() {
    return this.item.status !== 'RESERVATION_CLAIMED' && this.item.reservationId;
  }

  isItemEditable() {
    return ['NEW', 'PENDING'].includes(this.item.status);
  }

  isItemSold() {
    return this.item.status === 'SOLD';
  }

  isItemReserved() {
    return this.item.status.startsWith('RESERVATION_');
  }

  goToStockedItemReservationReceipt() {
    this.$location.path(`/customer/${this.customerId}/stocked-items/item/${this.item.id}/reservation/receipt`);
  }

  goToStockedItemSaleReceipt() {
    this.$location.path(`/customer/${this.customerId}/stocked-items/item/${this.item.id}/sale/receipt`);
  }

  goToTransferToBranchPage() {
    this.$location.path(`/dashboard/lookup-item/${this.item.id}/transfer-to-branch`);
  }

  goToTransferToHeadOfficePage() {
    this.$location.path(`/dashboard/lookup-item/${this.item.id}/transfer-to-head-office`);
  }

  isTransferAvailable() {
    return ['NEW', 'PENDING', 'UNSOLD'].includes(this.item.status);
  }

  async confirmTransfer() {
    const branch = this.branches.find(b => b.id === this.item.branchId);
    const confirmed = await this.confirmation(`Do you confirm this item is in ${branch.name}?`);
    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('ConfirmTransferStockItem', {itemId: this.item.id}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    this.$route.reload();
  }

  isConfirmTransferAvailable() {
    return ['TRANSFERRING', 'WITHDRAWN'].includes(this.item.status);
  }

  isMarkInvalidAvailable() {
    return ['NEW', 'PENDING', 'UNSOLD'].includes(this.item.status);
  }

  async confirmItemInvalid() {
    const confirmed = await this.confirmation(`Do you want to mark this item INVALID?`);
    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('MarkStockItemInvalid', {itemId: this.item.id}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    this.$route.reload();
  }

  getProductPageUrl() {
    if (this.item.productGroup === 'PAWN' && this.authentication.permissions['CST_PAWNS_READ']) {
      return `/customer/${this.item.productCustomerId}/pawns/${this.item.productId}`;
    } else if (this.item.productGroup === 'LAYAWAY' && this.authentication.permissions['CST_LAYAWAYS_READ']) {
      return `/customer/${this.item.productCustomerId}/layaways/${this.item.productId}`;
    }
    return null;
  }

  isItemPawned() {
    return ['PAWNED_ACTIVE', 'PAWNED_EXPIRED', 'WITHDRAWN', 'REDEEMED'].includes(this.item.status);
  }

  isProductAvailable() {
    return this.item.productId && this.getProductPageUrl();
  }

  goToProductPage() {
    this.$location.path(this.getProductPageUrl());
  }

  isItemStickerAvailable() {
    return !!(this.item.trackingCode);
  }

  goToItemSticker() {
    this.$location.path(`/dashboard/lookup-item/${this.item.id}/sticker`);
  }

  isHistoryAvailable() {
    return this.authentication.permissions['LOOKUP_ITEM_READ'];
  }

  goToHistory() {
    this.$location.path(`/dashboard/lookup-item/${this.item.id}`);
  }

  goToSellPage() {
    this.$location.path(`/dashboard/stocked-items/item/${this.item.id}/sell`);
  }

  goToReservationPage() {
    if (this.item.reservation) {
      this.$location.path(`/customer/${this.item.reservation.customerId}/stocked-items/item/${this.item.id}/reservation`);
    } else {
      this.$location.path(`/dashboard/stocked-items/item/${this.item.id}/reserve`);
    }
  }

  goToEditPage() {
    this.$location.path(`/dashboard/stocked-items/item/${this.item.id}/edit`);
  }

  async moveReadyForSaleStockItemToPending() {
    const proceed = await this.confirmation('Do you want to proceed in moving this item to PENDING?');
    if (!proceed) {
      return;
    }

    await this.pawnService.moveReadyForSaleStockItemToPending(this.item.id);
    this.$route.reload();
  }

  async deleteNewStockItem() {
    const proceed = await this.confirmation('Do you want to proceed in deleting this item?');
    if (!proceed) {
      return;
    }

    await this.pawnService.deleteNewStockItem(this.item.id);
    this.$route.reload();
  }
}

module.component('itemActions', {
  templateUrl,
  bindings: {
    item: '<'
  },
  controller: ItemActionsComponent
});
