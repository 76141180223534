import module from 'module';
import _ from 'lodash';

import './stock-item-transfer-to-head-office.style.less';
import templateUrl from './stock-item-transfer-to-head-office.template.html';

class StockItemTransferToHeadOfficeView {
  constructor($scope, $routeParams, $location, $filter, $route, command, http,
              authentication, notification, confirmation, branchService, reportService,
              popup, systemPropertyCache, nav, pawnItemCache) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$filter = $filter;
    this.$route = $route;
    this.command = command;
    this.http = http;
    this.authentication = authentication;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.systemPropertyCache = systemPropertyCache;
    this.nav = nav;
    this.pawnItemCache = pawnItemCache;
  }

  async $onInit() {
    await this.setHeadOfficeBranch();
    this.itemId = this.$route.current.params['itemId'];
  }

  async setHeadOfficeBranch() {
    const properties = await this.systemPropertyCache.toPromise();
    const headOfficeId = _.find(properties, {code: 'HEAD_OFFICE_BRANCH_ID'}).value
    const branches = await this.branchService.toPromise();
    this.branches = branches.filter(b => b.id === Number(headOfficeId))
  }

  async transfer() {
    const confirm = await this.confirmation(`Do you want send item to ${this.branches[0].name}?`);
    if (!confirm) {
      return;
    }

    const response = await this.command.execute('TransferStockItemToHeadOffice', {itemId: this.itemId}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    this.nav.back();
  }
}

module.component('stockItemTransferToHeadOfficeView', {
  templateUrl: templateUrl,
  controller: StockItemTransferToHeadOfficeView
});
