import $ from 'jquery';

import module from 'module';
import _ from 'lodash';

import templateUrl from './lookup-item.template.html';

class LookupItemComponent {
  constructor(http, $location, authentication, branchService, requestFormatter) {
    this.http = http;
    this.$location = $location;
    this.authentication = authentication;
    this.branchService = branchService;
    this.requestFormatter = requestFormatter;
  }

  $onInit() {
    this.searchParams = {
      trackingCode: null
    };
    this.searchResults = null;
    this.overflow = false;
    this.searchPerformed = false;
    this.contextBranchId = this.authentication.context.branchId;
  }

  validSearchRequest() {
    return this.searchParams.trackingCode != null;
  }

  emptyResults() {
    return this.searchResults !== null && this.searchResults.length === 0;
  }

  showResults() {
    return this.searchPerformed && !this.emptyResults();
  }

  nullifyParams(searchParams) {
    _.each(searchParams, function (value, key) {
      if (value != null && $.trim(value) == '') {
        searchParams[key] = null;
      }
    });
  }

  async search() {
    this.nullifyParams(this.searchParams);

    const queryParams = this.requestFormatter.convertToQueryParams(this.searchParams);
    this.searchResults = await this.http.get(`/products/pawns/items/history${queryParams}`, {nxLoaderText: 'Searching for item...'})
      .toPromise();

    this.searchPerformed = true;

    // If search result contains exactly one active customer -> open it
    if (this.searchResults && this.searchResults.length === 1) {
      this.openItemHistory(this.searchResults[0].id);
    } else {
      console.error('results', this.searchResults);
    }
  }

  buildRedirectUrl(itemId) {
    if (!this.authentication.permissions['LOOKUP_ITEM_READ']) {
      return;
    }
    return `/dashboard/lookup-item/${itemId}`;
  }

  openItemHistory(itemId) {
    this.$location.path(this.buildRedirectUrl(itemId));
  }
}

module.component('lookupItem', {
  templateUrl: templateUrl,
  controller: LookupItemComponent
});
