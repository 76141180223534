import module from 'module';

import './stock-item-transfer.style.less';
import templateUrl from './stock-item-transfer.template.html';

class StockItemTransferComponent {
  constructor($scope, $routeParams, $location, $filter, $route, command, http,
              authentication, notification, confirmation, branchService, reportService,
              popup, systemPropertyCache, pawnItemCache, nav) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$filter = $filter;
    this.$route = $route;
    this.command = command;
    this.http = http;
    this.authentication = authentication;
    this.notification = notification;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.reportService = reportService;
    this.popup = popup;
    this.systemPropertyCache = systemPropertyCache;
    this.pawnItemCache = pawnItemCache;
    this.nav = nav;
  }

  async $onInit() {
    this.item = await this.pawnItemCache.withParam(this.itemId).toPromise();

    if (this.availableBranches && this.availableBranches.length === 1) {
      this.branch = this.availableBranches[0];
    }
  }

  cancel() {
    this.nav.back();
  }

  async transfer() {
    this.onTransfer({input: {targetBranchId: this.branch.id}});
  }
}

module.component('stockItemTransfer', {
  templateUrl: templateUrl,
  bindings: {
    availableBranches: '<',
    itemId: '<',
    onTransfer: '&'
  },
  controller: StockItemTransferComponent
});
