import module from 'module';
import _ from 'lodash';

import './stock-item-transfer-to-branch.style.less';
import templateUrl from './stock-item-transfer-to-branch.template.html';

class StockItemTransferToBranchView {
  constructor($scope, $routeParams, $location, $filter, $route, command, http,
              authentication, notification, confirmation, branchService, reportService,
              popup, systemPropertyCache, nav, pawnItemCache) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.$filter = $filter;
    this.$route = $route;
    this.command = command;
    this.http = http;
    this.authentication = authentication;
    this.confirmation = confirmation;
    this.branchService = branchService;
    this.systemPropertyCache = systemPropertyCache;
    this.nav = nav;
    this.pawnItemCache = pawnItemCache;
  }

  async $onInit() {
    await this.setNonHeadOfficeBranches();
    this.itemId = this.$route.current.params['itemId'];
  }

  async setNonHeadOfficeBranches() {
    const properties = await this.systemPropertyCache.toPromise();
    const headOfficeId = _.find(properties, {code: 'HEAD_OFFICE_BRANCH_ID'}).value
    const branches = await this.branchService.toPromise();
    this.branches = branches.filter(b => b.id !== headOfficeId)
  }

  async transfer(input) {
    const selectedBranch = this.branches.find(b => b.id === input.targetBranchId);
    const confirm = await this.confirmation(`Do you want send item to ${selectedBranch.name}?`);
    if (!confirm) {
      return;
    }

    const request = {
      ...input,
      itemId: this.itemId
    };
    const response = await this.command.execute('TransferStockItemToBranch', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.pawnItemCache.evict();
    this.nav.back();
  }
}

module.component('stockItemTransferToBranchView', {
  templateUrl: templateUrl,
  controller: StockItemTransferToBranchView
});
