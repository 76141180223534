import module from 'module';
import LocalCache from 'shared/util/localCache';
import MemoryFactory from 'shared/util/localCacheMemoryFactory'

module.factory('availableTasksCache', (http, CacheFactory, config, authentication) =>
  new LocalCache({
    provider: () => http.get(`/tasks/counter?userId=${authentication.context.id}&displayMode=UNASSIGNED&displayMode=ASSIGNED&status=PENDING`, {nxLoaderSkip: true}),
    cacheFactory: MemoryFactory,
    cacheName: 'availableTasks'
  }));
