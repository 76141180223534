// initializes routes

import _ from "lodash";

export default function ($routeProvider) {
  $routeProvider

    .when('/general-ledger/accounts', {
      template: '<gl-accounts/>',
      label: 'Accounts'
    })
    .when('/general-ledger/accounts/:accountId', {
      template: '<gl-account-details/>',
      label: 'gl-account-details-label',
    })
    .when('/general-ledger/transactions', {
      template: '<gl-transactions/>',
      label: 'Transactions'
    })
    .when('/general-ledger/transactions/:accountId/create', {
      template: '<gl-transaction-create backdated="false"/>',
      label: 'gl-transaction-create-label'
    })
    .when('/general-ledger/transactions/:accountId/create-backdated', {
      template: '<gl-transaction-create backdated="true"/>',
      label: 'Create backdated GL transaction'
    })
    .when('/general-ledger/transactions/:accountId', {
      template: '<gl-transaction-list/>',
      label: 'gl-transaction-list-label'
    })
    .when('/general-ledger/reports', {
      template: '<gl-reports/>'
    })

    // dashboard
    .when('/dashboard/attendance', {
      template: '<attendance/>',
      label: 'Attendance',
    })

    .when('/dashboard/actions', {
      template: '<dashboard-action-list/>',
      label: 'Actions',
    })
    .when('/dashboard/actions/sent-by-me', {
      template: '<dashboard-action-list/>',
      label: null,
    })
    .when('/dashboard/appraisals', {
      template: '<pawned-items-list/>',
      label: 'Pawned items',
    })
    .when('/dashboard/stocked-items', {
      template: '<stock-items-inventory-view/>',
      label: 'Stocked items',
    })
    .when('/dashboard/stocked-items/item/:pawnItemId/sell', {
      template: '<stock-item-sell-view/>',
      label: 'Sell stock item',
    })
    .when('/dashboard/stocked-items/item/:itemId/reserve', {
      template: '<stock-item-reservation-view/>',
      label: 'Reserve stock item',
    })
    .when('/dashboard/stocked-items/item/create', {
      template: '<new-stock-item/>',
      label: 'New stock inventory item',
    })
    .when('/dashboard/stocked-items/item/:itemId/edit', {
      template: '<new-stock-item/>',
      label: 'Edit stock inventory item',
    })
    .when('/dashboard/actions/:action/modes/:mode/objects/:object/view-profile', {
      template: '<dashboard-action-view-profile/>',
      label: 'View Profile'
    })
    .when('/dashboard/stocked-items/batch-upload', {
      template: '<stock-item-batch-upload/>',
      label: 'Stock items batch upload'
    })
    .when('/dashboard/day-management', {
      template: '<day-management/>'
    })
    .when('/dashboard/history/miscellaneous-transactions', {
      template: '<history-miscellaneous-transactions/>',
      label: 'Transactions history'
    })
    .when('/dashboard/new-customer', {
      template: '<new-customer/>',
      label: 'New customer'
    })
    .when('/dashboard/lookup-customer', {
      template: '<lookup-customer/>',
      label: 'Lookup customer profile'
    })
    .when('/dashboard/lookup-group', {
      template: '<lookup-customer-group/>',
      label: 'Lookup group'
    })
    .when('/dashboard/lookup-item', {
      template: '<lookup-item/>',
      label: 'Lookup item'
    })
    .when('/dashboard/lookup-item/:itemId', {
      template: '<item-history item-id="$resolve.itemId"></item-history>',
      resolve: {
        itemId: /* @ngInject */ ($route) => $route.current.params['itemId']
      },
      label: 'Item tracking',
    })
    .when('/dashboard/lookup-item/:itemId/sticker', {
      template: '<item-sticker-view/>',
      label: 'Item sticker',
    })
    .when('/dashboard/lookup-item/:itemId/transfer-to-branch', {
      template: '<stock-item-transfer-to-branch-view/>',
      label: 'Stock item transfer to branch',
    })
    .when('/dashboard/lookup-item/:itemId/transfer-to-head-office', {
      template: '<stock-item-transfer-to-head-office-view/>',
      label: 'Stock item transfer to head office',
    })
    .when('/dashboard/miscellaneous-transactions', {
      template: '<miscellaneous-transactions/>',
      label: 'Transactions'
    })
    .when('/dashboard/miscellaneous-transactions/start-the-day', {
      template: '<start-the-day/>',
      label: 'Start the day'
    })
    .when('/dashboard/miscellaneous-transactions/cash-transfer-to-teller', {
      template: '<cash-transfer-to-teller action-type="CASH_TRANSFER_TO_TELLER" forex="false"/>',
      label: 'Cash transfer to teller'
    })
    .when('/dashboard/miscellaneous-transactions/forex-cash-transfer-to-teller', {
      template: '<cash-transfer-to-teller action-type="FOREX_CASH_TRANSFER_TO_TELLER" forex="true"/>',
      label: 'Forex cash transfer to teller'
    })
    .when('/dashboard/miscellaneous-transactions/teller-cash-overage', {
      template: '<teller-cash-overage/>',
      label: 'Teller Cash overage'
    })
    .when('/dashboard/miscellaneous-transactions/teller-cash-shortage', {
      template: '<teller-cash-shortage/>',
      label: 'Teller Cash shortage'
    })
    .when('/dashboard/miscellaneous-transactions/cash-to-depository-bank', {
      template: '<cash-to-depository-bank/>',
      label: 'Cash to depository bank'
    })
    .when('/dashboard/miscellaneous-transactions/cash-withdrawal-from-depository-bank', {
      template: '<cash-withdrawal-from-depository-bank/>',
      label: 'Cash withdrawal from depository bank'
    })
    .when('/dashboard/miscellaneous-transactions/official-receipt/:actionType', {
      template: '<official-receipt/>',
      label: 'Official receipt'
    })
    .when('/dashboard/miscellaneous-transactions/pos-cash-withdrawal', {
      template: '<pos-cash-withdrawal/>',
      label: 'POS cash withdrawal'
    })
    .when('/dashboard/miscellaneous-transactions/cashier-check-encashment', {
      template: '<cashier-check-encashment/>',
      label: 'Cashier check encashment'
    })
    .when('/dashboard/miscellaneous-transactions/branch-cash-transfer', {
      template: '<branch-cash-transfer/>',
      label: 'Branch cash transfer'
    })
    .when('/dashboard/miscellaneous-transactions/close-counters', {
      template: '<close-counters/>',
      label: 'Close counters'
    })
    .when('/dashboard/miscellaneous-transactions/end-the-day', {
      template: '<end-the-day/>',
      label: 'End the day'
    })
    .when('/dashboard/miscellaneous-transactions/cash-transfer-to-cashier', {
      template: '<cash-transfer-to-cashier action-type="CASH_TRANSFER_TO_CASHIER" forex="false"/>',
      label: 'Cash transfer to cashier'
    })
    .when('/dashboard/miscellaneous-transactions/forex-cash-transfer-to-cashier', {
      template: '<cash-transfer-to-cashier action-type="FOREX_CASH_TRANSFER_TO_CASHIER" forex="true"/>',
      label: 'Forex cash transfer to cashier'
    })
    .when('/dashboard/miscellaneous-transactions/check-transfer-to-cashier', {
      template: '<check-transfer-to-cashier/>',
      label: 'Check transfer to cashier'
    })
    .when('/dashboard/miscellaneous-transactions/misc-cash-disbursement', {
      template: '<misc-cash-disbursement/>',
      label: 'Misc cash disbursement'
    })
    .when('/dashboard/miscellaneous-transactions/inter-branch-cash-disbursement', {
      template: '<inter-branch-cash-disbursement/>',
      label: 'Inter branch cash disbursement'
    })
    .when('/dashboard/miscellaneous-transactions/cashier-cash-overage', {
      template: '<cashier-cash-overage/>',
      label: 'Cashier cash overage'
    })
    .when('/dashboard/miscellaneous-transactions/cashier-cash-shortage', {
      template: '<cashier-cash-shortage/>',
      label: 'Cashier cash shortage'
    })
    .when('/dashboard/miscellaneous-transactions/cashiers-check-creation', {
      template: '<cashiers-check-creation/>',
      label: 'Cashiers check creation'
    })
    .when('/dashboard/miscellaneous-transactions/funds-movement/:actionType', {
      template: '<funds-movement/>',
      label: 'Funds movement'
    })
    .when('/dashboard/miscellaneous-transactions/forex-interbranch-cash-transfer', {
      template: '<forex-interbranch-cash-transfer/>',
      label: 'Forex Interbranch Cash Transfer'
    })
    .when('/dashboard/collections', {
      template: '<collections/>',
      label: 'Collections'
    })
    .when('/dashboard/pawn-mass-withdrawal', {
      template: '<pawn-mass-withdrawal/>',
      label: 'Pawn Withdrawal',
    })
    .when('/dashboard/health-check', {
      template: '<system-health-check/>',
      label: 'Health Check',
    })

    // report
    .when('/report/:reportType', {
      template: '<report-list/>',
      label: 'report-list'
    })
    .when('/report/:reportType/reports/:reportCode', {
      template: '<report-details/>',
      label: 'report-details'
    })
    .when('/custom-report', {
      template: '<custom-report/>',
      label: 'custom-report'
    })

    // customer
    .when('/customer/:customerId', {
      template: '<customer-landing-page>',
      label: 'customer' // replaced by customer name in controllers
    })
    .when('/customer/:customerId/stocked-items', {
      template: '<customer-stock-items/>',
      label: 'Stocked items'
    })
    .when('/customer/:customerId/stocked-items/item/:itemId/reservation', {
      template: '<stock-item-reservation-view/>',
      label: 'Stock item reservation',
    })
    .when('/customer/:customerId/stocked-items/item/:itemId/reservation/receipt', {
      template: '<stocked-item-reservation-receipt-view/>',
      label: 'Stocked item reservation receipt',
    })
    .when('/customer/:customerId/stocked-items/item/:itemId/sale/receipt', {
      template: '<stocked-item-sale-receipt-view/>',
      label: 'Stocked item sale receipt',
    })

    .when('/customer/:customerId/forex', {
      template: `<customer-forex
                   customer-id="$resolve.customerId"
                   main-currency-iso="$resolve.mainCurrencyIso"/>`,
      label: 'Forex',
      resolve: {
        customerId: /* @ngInject */ ($route) => $route.current.params['customerId'],
        mainCurrencyIso: /* @ngInject */ (systemPropertyCache) => {
          return systemPropertyCache.toPromise().then(properties => {
            const prop = _.find(properties, {code: 'MAIN_CURRENCY_ISO_CODE'});
            return prop ? prop.value : null;
          });
        }
      }
    });

  admin($routeProvider);
  prints($routeProvider);
  customerPawns($routeProvider);
  customerLayaways($routeProvider);
  support($routeProvider);

  $routeProvider.when('/customer/:customerId/profile', {
    template: '<customer-profile/>',
    label: 'Profile'
  }).when('/customer/:customerId/profile/merge', {
      template: '<customer-merge/>',
      label: 'Merge profiles'
  }).when('/customer/:customerId/risk-profile', {
    template: '<customer-risk-profile-view/>',
    label: 'Risk profile'
  })

  // settings
    .when('/user-settings', {
      template: '<user-settings/>'
    })

    .otherwise('/dashboard/lookup-customer');
};

function admin(routeProvider) {
  return routeProvider
  // admin
    .when('/admin/pawn-products', {
      template: '<pawn-products/>',
      label: 'Pawns'
    })
    .when('/admin/pawn-products/create', {
      template: '<pawn-product-details/>',
      label: 'Create New'
    })
    .when('/admin/pawn-products/:productId', {
      template: '<pawn-product-details/>',
      label: 'pawn-product-label'
    })
    .when('/admin/layaway-products', {
      template: '<layaway-products/>',
      label: 'Layaways'
    })
    .when('/admin/layaway-products/create', {
      template: '<layaway-product-details/>',
      label: 'Create New'
    })
    .when('/admin/layaway-products/:productId', {
      template: '<layaway-product-details/>',
      label: 'layaway-product-label'
    })
    .when('/admin/security', {
      template: '<security/>',
      label: 'Security'
    })
    .when('/admin/security/roles/:roleId', {
      template: '<roles-config/>',
      label: 'role'   // Will be replaced by role name in controller
    })

    .when('/admin/compliance', {
      template: '<compliance-list-view/>',
      label: 'Compliance'
    })
    .when('/admin/compliance/force-profile-update', {
      template: '<force-profile-update-view/>',
      label: 'Force profile update'
    })
    .when('/admin/compliance/risk/assessment', {
      template: '<risk-assessment-view/>',
      label: 'Risk assessment'
    })
    .when('/admin/compliance/risk/categories', {
      template: '<risk-categories-view/>',
      label: 'Risk categories'
    })
    .when('/admin/compliance/risk/categories/create', {
      template: '<risk-category-details-view/>',
      label: 'Create new risk category'
    })
    .when('/admin/compliance/risk/categories/:riskCategoryId', {
      template: '<risk-category-details-view/>',
      label: 'risk-category-label'
    })
    .when('/admin/compliance/watchlist', {
      template: '<watchlist-view/>',
      label: 'Watchlist'
    })
    .when('/admin/compliance/watchlist/create', {
      template: '<watchlist-details-view/>',
      label: 'Create new watchlist'
    })
    .when('/admin/compliance/watchlist/:watchlistId', {
      template: '<watchlist-details-view/>',
      label: 'watchlist-label'
    })

    // organization
    .when('/admin/organization', {
      template: '<organization/>',
      label: "Organization"
    })
    .when('/admin/organization/branches', {
      template: '<organization/>'
    })
    .when('/admin/organization/branches/create', {
      template: '<branch-details/>',
      label: 'Create new branch'
    })
    .when('/admin/organization/branches/:branchId', {
      template: '<branch-details/>',
      label: 'Branch details'
    })
    .when('/admin/organization/areas/create', {
      template: '<area-details/>',
      label: 'Create new area'
    })
    .when('/admin/organization/areas/:areaId', {
      template: '<area-details area-id="$resolve.areaId"/>',
      label: 'Area details',
      resolve: {
        areaId: /* @ngInject */ ($route) => {
          return Number($route.current.params['areaId']);
        }
      }
    })
    .when('/admin/organization/users/:branchId', {
      template: '<users-list/>',
      // Overridden in component
      label: 'users-list-label'
    })
    .when('/admin/organization/users/:branchId/user/:userId', {
      template: '<users-details/>',
      // Overridden in component
      label: 'users-details-label'
    })
    .when('/admin/organization/users/:branchId/user/:userId/move-to-branch', {
      template: '<users-change-branch user="$resolve.user" links="$resolve.links"/>',
      label: 'Move between branches',
      resolve: {
        user: /* @ngInject */ (userCache, $route) => {
          const userId = $route.current.params['userId'];
          return userCache.toPromise().then(users => _.find(users, u => u.id == userId));
        },
        links: /* @ngInject */ ($route) => {
          const {branchId, userId} = $route.current.params;
          return {
            cancel: `/admin/organization/users/${branchId}/user/${userId}`,
            success: `/admin/organization/users/${branchId}`
          };
        }
      }
    })
    .when('/admin/organization/pawn-auctions/:branchId', {
      template: '<pawn-auction-list/>',
      label: 'Pawn auctions'
    })
    .when('/admin/organization/depository-accounts', {
      template: '<organization/>',
      label: "Organization"
    })
    .when('/admin/organization/depository-accounts/create', {
      template: '<depository-account-details/>',
      label: 'Create depository account'
    })
    .when('/admin/organization/depository-accounts/:accountId/update', {
      template: '<depository-account-details/>'
    })
    .when('/admin/organization/holidays/:branch', {
      template: '<holiday-list/>',
      // Overridden in component
      label: 'holidays-label'
    })

    // General Ledger
    .when('/admin/general-ledger', {
      template: '<general-ledger/>',
      label: 'General Ledger'
    })
    .when('/admin/general-ledger/transactions', {
      template: '<transactions-list/>',
      label: 'Transactions'
    })
    .when('/admin/general-ledger/transactions/:actionType', {
      template: '<transactions-details/>',
      label: 'Transactions'
    })
    .when('/admin/general-ledger/transactions/:actionType/:id', {
      template: '<transactions-details/>',
      label: 'Transactions'
    })

    // Rates

    .when('/admin/pawned-item-rates', {
      template: '<pawned-item-rates/>',
      label: 'Pawned Item Rates'
    })
    .when('/admin/stocked-item-rates', {
      template: '<stocked-item-rates/>',
      label: 'Stocked Item Rates'
    })

    // Forex
    .when('/admin/forex', {
      template: '<forex-panel/>',
      label: 'Forex'
    })
    .when('/admin/forex/exchange-rates', {
      template: '<forex-panel/>',
      label: 'Forex'
    })
    .when('/admin/forex/transacting-state', {
      template: '<forex-panel/>',
      label: 'Forex'
    })
    .when('/admin/forex/document-types', {
      template: '<forex-panel/>',
    })
    .when('/admin/forex/document-types/create', {
      template: '<forex-document-type-details/>',
      label: 'Create new forex document'
    })
    .when('/admin/forex/document-types/:documentTypeId', {
      template: '<forex-document-type-details/>',
      label: 'Forex document details'
    })

    //Logged-in Users
    .when('/admin/user-login-record', {
      template: '<user-login-record/>',
      label: 'User Login Record'
    })
    .when('/admin/user-logins/logged-in-users', {
      template: '<user-logins/>',
      label: 'Logged in Users'
    })

    // Notification
    .when('/notification/sms-configuration', {
      template: '<notification-sms-configuration/>',
      label: 'Sms'
    })
    .when('/notification/schedules', {
      template: '<notification-schedules/>',
      label: 'Schedules'
    })
    .when('/notification/template', {
      template: '<notification-template/>',
      label: 'Create template'
    })
    .when('/notification/template/:templateId', {
      template: '<notification-template template-id="$resolve.templateId"/>',
      label: 'Edit template',
      resolve: {
        templateId: /* @ngInject */ ($route) => $route.current.params['templateId']
      }
    });
}

function prints($routeProvider) {
  const printParametersResolve = {
    printParameters:  /* @ngInject */ ($route, http) => {
      const printCode = $route.current.params['printCode'];
      return http.get('/print/parameters').toPromise()
        .then(allPrintParameters => {
          const foundParameters = Object.entries(allPrintParameters)
            .find(([key, value]) => key === printCode);

          // [key, value] array
          return foundParameters[1].parameters;
        });
    }
  };
  $routeProvider.when('/admin/prints', {
    template: `<prints-page header-label="Description:" header-title="List of the prints">
                    <prints-list non-parametrized-prints="$resolve.nonParametrizedPrints" available-print-parameters="$resolve.availablePrintParameters"/>
                 </prints-page>`,
    label: 'Prints',
    resolve: {
      nonParametrizedPrints: /* @ngInject */ (printsCache) => printsCache.toPromise()
        .then(allPrints => allPrints.filter(print => {
          const printParameters = _.get(print, 'parameters', {});
          return _.isEmpty(printParameters);
        })),
      availablePrintParameters:  /* @ngInject */ (http) => http.get('/print/parameters').toPromise(),
    }
  })
    .when('/admin/prints/:id/:printCode', {
      template: `<prints-page header-label="Description:" header-title="Print Details">
                    <query-param-guard query-param-filter="$resolve.paramFilter">
                      <prints-details 
                        print-parameters="$resolve.printParameters"
                        print="$resolve.print" 
                      />
                    </query-param-guard>
                 </prints-page>`,
      label: 'Prints details',
      resolve: {
        ...printParametersResolve,
        print:  /* @ngInject */ ($route, printService) => {
          const printCode = $route.current.params.printCode;
          const pathParams = $route.current.pathParams;
          const allParams = $route.current.params;
          const printId = $route.current.params.id;
          const printParams = _.omitBy(allParams, (value, key) => key in pathParams);
          return printService.getPrint(printCode, printParams, printId);
        },
        paramFilter: () => ({newPath, oldParams}) => {
          return {};
        },
      }
    })
    .when('/admin/prints/:id/:printCode/parameters', {
      template: `<prints-page 
                      header-label="Description:" 
                      header-title="Print per product"
                      back-link="'/admin/prints'"
                      >
                  <prints-customization 
                    print-parameters="$resolve.printParameters" 
                    print-code="$resolve.printCode"
                    base-print="$resolve.basePrint"
                  />
               </prints-page>`,
      label: 'Prints customization',
      resolve: {
        ...printParametersResolve,
        printCode: /* @ngInject */ ($route) => $route.current.params['printCode'],
        basePrint: /* @ngInject */ ($route, printsCache) => printsCache.toPromise()
          .then(allPrints => allPrints.find(print => {
            if(print.code !== $route.current.params['printCode']) {
              return false;
            }

            return _.isEmpty(_.get(print, 'parameters', {}));
          }))
      }
    });
}

function customerPawns(routeProvider) {
  return routeProvider
    .when('/customer/:customerId/pawns/create', {
      template: '<customer-pawn-create-list/>',
      label: 'New pawn'
    })
    .when('/customer/:customerId/pawns/create/:pawnTypeId', {
      template: '<customer-pawn-create-details/>',
      label: '<null>'
    })
    .when('/customer/:customerId/pawns/:pawnId/release/cash', {
      template: '<customer-pawn-cash-release/>',
      label: 'Release pawn by cash'
    })
    .when('/customer/:customerId/pawns/:pawnId/redeem', {
      template: '<customer-pawn-redemption/>',
      label: 'Redeem pawn'
    })
    .when('/customer/:customerId/pawns/:pawnId/renew', {
      template: '<customer-pawn-renewal/>',
      label: 'Renew pawn'
    })
    .when('/customer/:customerId/pawns/:pawnId/renew-partial', {
      template: '<customer-pawn-partial-renewal/>',
      label: 'Partially renew pawn'
    })
    .when('/customer/:customerId/pawns/:pawnId/change-owner', {
      template: '<customer-pawn-change-owner/>',
      label: 'Change pawn owner'
    })
    .when('/customer/:customerId/pawns/:pawnId/redemption-partial', {
      template: '<customer-pawn-partial-redemption/>',
      label: 'Partially redeem pawn'
    })
    .when('/customer/:customerId/pawns/:pawnId/loan-addition', {
      template: '<customer-pawn-loan-addition/>',
      label: 'Loan addition'
    })
    .when('/customer/:customerId/pawns/:pawnId/withdraw', {
      template: '<customer-pawn-withdrawal/>',
      label: 'Withdraw pawn for auction'
    })
    .when('/customer/:customerId/pawns/:pawnId/ticket', {
      template: '<customer-pawn-ticket/>',
      label: 'Pawn ticket'
    })
    .when('/customer/:customerId/pawns', {
      template: '<customer-pawns/>',
      label: 'Pawns'
    })
    .when('/customer/:customerId/pawns/:pawnId', {
      template: '<customer-pawns/>',
      label: '<null>'
    })
    .when('/customer/:customerId/pawns/:pawnId/:amortizationId/cash-payment', {
      template: '<customer-pawns-cash-payment/>',
      label: 'Cash payment'
    })
    .when('/customer/:customerId/pawns/:pawnId/:amortizationId/check-payment', {
      template: '<customer-pawns-check-payment/>',
      label: 'Check payment'
    })
    .when('/customer/:customerId/pawns/:pawnId/update', {
      template: '<customer-pawns-update/>',
      label: 'Update pawn'
    })
    .when('/customer/:customerId/pawns/:pawnId/update-items', {
      template: '<customer-pawns-update-items/>',
      label: 'Update items'
    })
    .when('/customer/:customerId/pawns/:pawnId/update-items/:itemId', {
      template: '<customer-pawns-update-item/>',
      label: 'Update item'
    })
    .when('/customer/:customerId/pawns/create/:pawnTypeId/new-appraisal', {
      template: '<new-appraisal customer-id="$resolve.customerId" pawn-type-id="$resolve.pawnTypeId"/>',
      label: 'New appraisal',
      resolve: {
        customerId: /* @ngInject */ ($route) => $route.current.params['customerId'],
        pawnTypeId: /* @ngInject */ ($route) => $route.current.params['pawnTypeId']
      }
    })
    .when('/customer/:customerId/pawns/create/:pawnTypeId/edit-appraisal/:pawnItemId', {
      template: '<new-appraisal customer-id="$resolve.customerId" pawn-type-id="$resolve.pawnTypeId" pawn-item-id="$resolve.pawnItemId"/>',
      label: 'Edit appraisal',
      resolve: {
        customerId: /* @ngInject */ ($route) => $route.current.params['customerId'],
        pawnTypeId: /* @ngInject */ ($route) => $route.current.params['pawnTypeId'],
        pawnItemId: /* @ngInject */ ($route) => $route.current.params['pawnItemId']
      }
    })
    .when('/forex/dashboard', {
      template: '<forex-dashboard/>',
      label: 'Forex dashboard'
    })
    .when('/forex/calculator', {
      template: '<forex-calculator/>',
      label: 'Forex calculator'
    });

}

function customerLayaways(routeProvider) {
  return routeProvider
    .when('/customer/:customerId/layaways', {
      template: '<customer-layaways-view/>',
      label: 'Layaways'
    })
    .when('/customer/:customerId/layaways/:layawayId', {
      template: '<customer-layaways-view/>',
      label: '<null>'
    })
    .when('/customer/:customerId/layaways/:layawayId/payment', {
      template: '<layaway-payment-view/>',
      label: 'Layaway payment'
    })
    .when('/customer/:customerId/layaways/:layawayId/cancel', {
      template: '<layaway-cancel-view/>',
      label: 'Layaway cancellation'
    })
    .when('/customer/:customerId/layaways/:layawayId/agreement-form', {
      template: '<layaway-agreement-form-view/>',
      label: 'Layaway agreement form'
    })
    .when('/customer/:customerId/layaways/:layawayId/receipt', {
      template: '<layaway-receipt-view/>',
      label: 'Layaway receipt'
    })
    .when('/customer/:customerId/layaways/:layawayId/payments-report', {
      template: '<customer-layaway-payments-report-view/>',
      label: 'Layaway payments report'
    });
}

function support(routeProvider) {
  return routeProvider
    .when('/support/:topic', {
      template: '<support-case-picker/>',
      label: 'support-topic'
    });
}
