import module from 'module';
import Jspdf from 'jspdf';
import {addFont} from './code-39.regular.font';
import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/first';
import _ from 'lodash';

export const DEFAULT_FONT_SIZE = 8;

module.factory('printService', function (http, notification, printBackgroundCache, printsCache) {
  return {
    printToContainer: function (doc, options, cb) {
      if (!options || !options.container) {
        console.log('ERROR: PrintService: No container provided.');
        return;
      }

      const backgroundEnabled = options.backgroundEnabled;

      function printWithoutBackground() {
        generatePdf(doc, options, null, cb);
      }

      if(!backgroundEnabled) {
        printWithoutBackground();
        return;
      }

      if ((doc.backgroundFileIds || []).length === 0 && (options.backgroundThumbnails || []).length === 0) {
        return printWithoutBackground();
      }

      Promise.resolve()
        .then(async () => {
          if ((options.backgroundThumbnails || []).length > 0) {
            if(options.backgroundThumbnails.some(item => !item)) {
              return Promise.reject("Cannot print for empty thumbnail");
            }

            return options.backgroundThumbnails
            //for unknown content-type force jpg
              .map(base64 => base64.replace(/^data:application\/octet-stream;base64,/, "data:image/jpg;base64,"));
          } else {
            // take images from cache
            const backgroundParams = {
              printCode: doc.code,
              params: {
                ...doc.parameters
              }
            };

            const images = await printBackgroundCache.withParam(backgroundParams).toPromise();
            return images.map(image => 'data:image/jpg;base64,' + image);
        }}).then(data => {
           generatePdf(doc, options, data, cb);
        }).catch(err => {
          console.error(err);
          return printWithoutBackground();
        });
    },
    async getPrint(printCode, printParams, printId) {
      const allPrints = await printsCache.toPromise();
      const basePrint = allPrints.find(print => print.code === printCode && print.id === Number(printId));

      if(_.isEmpty(printParams)) {
        return basePrint;
      }

      const specificPrint = allPrints.find(print => {
        if(print.code !== basePrint.code) {
          return false;
        }

        if(!print.parameters) {
          return false;
        }

        return _.isEqual(print.parameters, printParams);
      });

      if(specificPrint) {
        return specificPrint;
      }

      // we wanted to get specific print, but there was none created -> let's create one
      return {
        ...basePrint,
        parameters: printParams,
        id: null,
      };
    }
  };
});

function generatePdf(doc, options, backgroundBase64Images, cb) {
  let pdf = new Jspdf(doc.orientation, 'mm', [doc.longEdge, doc.shortEdge]);
  addFont(pdf);

  pdf.viewerPreferences({
    'PrintScaling': 'None'
  });

  if (options.autoPrint) pdf.autoPrint();

  pdf.setProperties({
    title: doc.title,
    subject: doc.subject,
    author: doc.author,
    keywords: doc.keywords,
    creator: 'Nextbank'
  });

  for (let i = 0; i < doc.pages.length; i++) {
    if (backgroundBase64Images && backgroundBase64Images.length > 0) drawBackground(pdf, doc, backgroundBase64Images[Math.min(i, backgroundBase64Images.length - 1)]);

    for (let line of doc.pages[i].lines) {
      if (!line.active) {
        continue;
      }

      if (line.text == null || !line.x || !line.y) {
        console.log('Incomplete print line', line);
        continue;
      }

      const fontName = line.customFont ? line.customFont : "Helvetica";

      pdf.setFontSize(line.fontSize ? line.fontSize : DEFAULT_FONT_SIZE);
      pdf.setFont(fontName, line.bold ? 'bold' : 'normal');

      if (line.maxLength) {
        let multiLine = pdf.splitTextToSize(line.text, line.maxLength);
        pdf.text(multiLine, line.x, line.y);
      } else {
        pdf.text(line.text, line.x, line.y);
      }
    }
    if(doc.pages[i+1]) {
      pdf.addPage();
    }
  }

  // feed the container
  //let data = pdf.output('datauri');
  let pdfURL = pdf.output('bloburl') + "#statusbar=0&toolbar=0&navpanes=0&scrollbar=0";
  options.container.attr('src', pdfURL );
  if (cb) cb(pdf);
}


function drawBackground(pdf, doc, img) {
  try {
    if(doc.orientation === 'PORTRAIT') {
      pdf.addImage(img, 'JPEG', 0, 0, doc.shortEdge, doc.longEdge);
    } else {
      pdf.addImage(img, 'JPEG', 0, 0, doc.longEdge, doc.shortEdge);
    }

  } catch (e) {
    console.log('Cannot draw background, invalid image source. Error: ' + e);
  }
}

function getBase64(file) {
  return Observable.create((observer) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      observer.next(reader.result);
      observer.complete();
    };
    reader.onerror = function (error) {
      observer.error('Error on reading file');
    };
  });

}
