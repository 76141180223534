import _ from 'lodash'

import module from 'module';


class PawnService {
  constructor($filter, command, pawnItemCache) {
    this.$filter = $filter;
    this.command = command;
    this.pawnItemCache = pawnItemCache;
  }

  mapDefectLevel(pawnItemDefectTypes, defectTypeId) {
    let defectType = _.find(pawnItemDefectTypes, {id: defectTypeId});
    return _.get(defectType,'name','-');
  }

  mapMetalType(metals, metalTypeId) {
    let metalType = _.find(metals, {id: metalTypeId});
    return _.get(metalType,'name','-');
  }

  getFinenessLabel(pawnMetalRates, metalRateId) {
    let metalRate = _.find(pawnMetalRates, {id: metalRateId});
    return _.get(metalRate,'fineness','-');
  }

  serializeAttribute(pawnItemAttributeTypes, attr) {
    let attrType = _.find(pawnItemAttributeTypes, {id: attr.attributeTypeId});
    if (!attrType) return null;
    let type = attr.extraType ? attr.extraType : attrType.name;
    return attr.value ? type + ' (' + attr.value + ')' : type;
  }

  isPawned(pawn) {
    return pawn.status == 'PAWNED_ACTIVE' || pawn.status == 'PAWNED_EXPIRED';
  }

  pushDetail(details, label, value, include = true, nullLabel = '-') {
    if (include) {
      details.push({
        label: label,
        value: value !== null ? value : nullLabel
      });
    }
  }

  buildDescription(item, pawnItemDefectTypes, pawnItemTypes, metals, pawnItemAttributeTypes, pawnMetalRates) {

    let details = [];

    // Common pawn item properties
    this.pushDetail(details, 'Item no.', item.id);
    this.pushDetail(details, 'Custom type', item.customType, item.customType !== null);
    this.pushDetail(details, 'Status', this.$filter('prettyEnum')(item.status));
    this.pushDetail(details, 'Value', this.$filter('php')(item.valuation));
    this.pushDetail(details, 'Defect level', this.mapDefectLevel(pawnItemDefectTypes, item.defectId));
    this.pushDetail(details, 'Quantity', item.quantity, item.quantity !== null);

    // For JEWELRY add extra details
    if (item.categoryId) {
      let c = _.find(pawnItemTypes, {id: item.categoryId});
      if (c.category === 'JEWELRY') {
        this.pushDetail(details, 'Metal', this.mapMetalType(metals, item.metalTypeId));
        this.pushDetail(details, 'Fineness', this.getFinenessLabel(pawnMetalRates, item.metalRateId));
        this.pushDetail(details, 'Weight', item.weight + ' grams', item.weight !== null);
      }
    }

    // Append item attributes
    if (item.attributes !== null && item.attributes.length > 0) {
      let attributes = _.map(item.attributes, (a) => this.serializeAttribute(pawnItemAttributeTypes, a));
      this.pushDetail(details, 'Extra description', _.join(attributes, ', '));
    }

    // Append item remarks
    this.pushDetail(details, 'Remarks', item.remarks, item.remarks !== null);

    return details;
  }

  async moveReadyForSaleStockItemToPending(itemId) {
    await this.command.execute('MoveReadyForSaleStockItemToPending', {itemId: itemId}).toPromise();
    this.pawnItemCache.evict();
  }

  async deleteNewStockItem(itemId) {
    await this.command.execute('DeleteNewStockItem', {itemId: itemId}).toPromise();
    this.pawnItemCache.evict();
  }
}

module.factory('pawnService', ($filter, command) =>
  new PawnService($filter, command)
);
