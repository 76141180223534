import module from 'module';
import _ from 'lodash';
import {NgTableParams} from 'ng-table/ng-table';

import templateUrl from './item-history.template.html';
import './item-history.less.css';

class ItemHistory {

  constructor(postingDateService, http, reportService, $route, branchService, pawnItemCache, userCache) {
    this.postingDateService = postingDateService;
    this.http = http;
    this.reportService = reportService;
    this.branchService = branchService;
    this.pawnItemCache = pawnItemCache;
    this.usersCache = userCache;
  }

  async $onInit() {
    [this.branches, this.item, this.users] = await Promise.all([
      this.branchService.toPromise(),
      this.pawnItemCache.withParam(this.itemId).toPromise(),
      this.usersCache.toPromise()
    ]);
    this.loadHistory();
  }

  loadHistory() {
    this.transactionHistoryConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async params => {
        if (this.itemId) {
          const response = await this.fetchHistory(params);

          this.transactionHistoryConfig.total(response.length);
          return response.map(r => ({
            ...r,
            executedByName: this.getUsername(r.executedBy),
            sourceBranchName: this.getBranchName(r.sourceBranchId),
            targetBranchName: this.getBranchName(r.targetBranchId)
          })).sort(this.latestActionsAtTheTop());
        }
        return Promise.resolve([]);
      }
    });
  }

  async fetchHistory(params) {
    return await this.http.get(`/products/pawns/items/${this.itemId}/history`, {
      pageNo: params.page() - 1,
      pageSize: params.count(),
      itemId: this.itemId
    }).toPromise();
  }

  getBranchName(branchId) {
    return (_.find(this.branches, {id: branchId}) || {}).name;
  }

  latestActionsAtTheTop() {
    return (a, b) => b.id - a.id;
  }

  getUsername(userId) {
    return (_.find(this.users, {id: userId}) || {}).username;
  }
}

module.component('itemHistory', {
  templateUrl: templateUrl,
  bindings: {
    itemId: '<'
  },
  controller: ItemHistory
});
